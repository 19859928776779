export const response = [
    {
        "id": "1",
        "title": "How much of the heat generated by climate change does the ocean soak up?",
        "image": "url",
        "answers": [
            "52%",
            "19%",
            "77%"
        ],
        "correct": "93%",
        "description": "Air temperatures would soar by <b>more than 60°C</b> if the heat that the ocean absorbed from 1955 were added to the atmosphere.",
        "source": {
            "National Geographic": "https://www.nationalgeographic.com/environment/article/oceans-warming-faster-than-ever",
            "Climate": "https://www.climate.gov/news-features/understanding-climate/climate-change-ocean-heat-content"
        }
    },
    {
        "id": "10",
        "title": "Producing 1 litre of dairy milk requires ____ more water than the same amount of oat milk.",
        "image": "url",
        "answers": [
            "4 times",
            "8 times",
            "11 times"
        ],
        "correct": "13 times",
        "description": "According to a report, producing 1 litre of dairy milk requires 628.2 litres of fresh water. The same amount of oat milk requires 48.24 litres. ",
        "source": {
            "TED": "https://ideas.ted.com/which-plant-based-milk-is-best-for-the-planet/",
            "Our World In Data": "https://ourworldindata.org/environmental-impacts-of-food"
        }
    },
    {
        "id": "11",
        "title": "How much more carbon would driving a petrol car to work emit than taking the bus?",
        "image": "url",
        "answers": [
            "30%",
            "60%",
            "45%"
        ],
        "correct": "82%",
        "description": "According to the UK Department for Business, Energy & Industrial Strategy, the carbon footprint per kilometre of a medium petrol car is 192g. That of a passenger on a bus is 105g.",
        "source": {
            "Our World In Data": "https://ourworldindata.org/travel-carbon-footprint"
        }
    },
    {
        "id": "12",
        "title": "Which of the following has the least impact on the environment during production? ",
        "image": "url",
        "answers": [
            "Organic cotton tote bag",
            "Normal cotton tote bag"
        ],
        "correct": "Plastic bag",
        "description": "According to a study, an organic cotton tote has to be used daily 20,000 times to offset its overall impact of production. A normal cotton bag, 7,100 times. ​  <br /><br />​  A plastic bag (LDPE carrier bag) has to be reused just twice.​  <br /><br />​  Production is only part of a product's life cycle. We should also consider the environmental impact of its disposal.",
        "source": {
            "MST": "https://www2.mst.dk/udgiv/publications/2018/02/978-87-93614-73-4.pdf"
        }
    },
    {
        "id": "13",
        "title": "Paper cups make up what proportion of a takeaway café latte’s carbon footprint?",
        "image": "url",
        "answers": [
            "73%",
            "9%",
            "14%"
        ],
        "correct": "4%",
        "description": "Energy use and coffee and milk production account for the rest of a takeaway café latte’s carbon footprint.",
        "source": {
            "Huhtamaki": "https://www.huhtamaki.com/globalassets/global/highlights/responsibility/taking-a-closer-look-at-paper-cups-for-coffee.pdf "
        }
    },
    {
        "id": "14",
        "title": "What proportion of websites are inaccessible to people with disabilities who rely on assistive technology?",
        "image": "url",
        "answers": [
            "30%",
            "70%",
            "50%"
        ],
        "correct": "90%",
        "description": "According to AbilityNet, more than 90% of websites don’t meet single-A compliance with the Web Content Accessibility Guidelines. The legal minimum is AA, a higher standard than single-A. ",
        "source": {
            "AbilityNet": "https://www.abilitynet.org.uk/news-blogs/inaccessible-websites-keep-disabled-people-out-work-abilitynet-tells-government-taskforce"
        }
    },
    {
        "id": "15",
        "title": "How many litres of water does a full flush toilet use per flush?",
        "image": "url",
        "answers": [
            "3",
            "4.5",
            "7"
        ],
        "correct": "11",
        "description": "A dual flush toilet reduces this amount to about 6 litres per full flush and 3 litres per half flush.",
        "source": {
            "SA Water": "https://www.sawater.com.au/__data/assets/pdf_file/0008/6686/Factsheet_Amenities.pdf"
        }
    },
    {
        "id": "16",
        "title": "A plant-based burger requires  ______ less water to produce than an average hamburger.",
        "image": "https://assets.weforum.org/editor/large_Ls-DSMp23u4OcdCK_HF8tTXhdppawVi0jQgQQkek3c0.png",
        "answers": [
            "30%",
            "60%",
            "10%"
        ],
        "correct": "75%",
        "description": "Overall, it causes at least 87% fewer emissions than a hamburger.",
        "source": {
            "World Economic Forum": "https://www.weforum.org/agenda/2019/02/this-is-how-much-water-is-in-your-burger/"
        }
    },
    {
        "id": "17",
        "title": "Which of the following sectors is most exposed to long-term climate risks?",
        "image": "url",
        "answers": [
            "Steel",
            "Copper",
            "Gold"
        ],
        "correct": "Coal",
        "description": "Thermal coal mining faces a long-term existential threat. Coal-powered energy generation is the single largest source of greenhouse gas emissions, and cleaner sources of energy are already available.",
        "source": {
            "Fitch Ratings": "https://www.fitchratings.com/research/corporate-finance/ferrous-coal-most-exposed-to-climate-risk-in-metals-mining-23-05-2022",
            "McKinsey": "https://www.mckinsey.com/business-functions/sustainability/our-insights/climate-risk-and-decarbonization-what-every-mining-ceo-needs-to-know  "
        }
    },
    {
        "id": "18",
        "title": "Which region would be most severely impacted if global temperatures reach 3°C above pre-industrial levels?",
        "image": "url",
        "answers": [
            "The Americas",
            "Europe"
        ],
        "correct": "Asia-Pacific",
        "description": "The Asia-Pacific region could face a cumulative economic loss of US$96 trillion by 2070 if no rapid action is taken to reduce its emissions. <br /><br /> But the region could grow around US$9 trillion a year if steps are taken to reduce emissions.",
        "source": {
            "The Straits Times": "https://www.straitstimes.com/singapore/environment/world-stands-to-lose-245-trillion-by-2070-if-temperatures-rise-by-3-deg-c-study",
            "Deloitte": "https://www2.deloitte.com/content/dam/Deloitte/dk/Documents/about-deloitte/Deloitte-Global-Turning-Point-2022.pdf"
        }
    },
    {
        "id": "19",
        "title": "On average, across the OECD, how much more do the richest 10% of the population earn than the poorest 10%? ",
        "image": "url",
        "answers": [
            "3 times",
            "5 times",
            "7 times"
        ],
        "correct": "10 times",
        "description": "Inequality drags down long-term economic growth and harms opportunities. In the mid-1980s, pensioners were more likely to live in poverty. Today it is young people and families with children.",
        "source": {
            "OECD": "https://www.oecd.org/social/inequality.htm"
        }
    },
    {
        "id": "2",
        "title": "With no climate-change action, the global economy could lose more than 18% of its GDP by 2048. <br /><br /> How much could ASEAN countries lose?",
        "image": "url",
        "answers": [
            "10.2%",
            "18%",
            "42.3%"
        ],
        "correct": "37.4%",
        "description": "According to Swiss Re, Indonesia, Malaysia, the Philippines, Singapore and Thailand could lose more than 7 times their 2019 GDP by 2050. ",
        "source": {
            "Swiss Re": "https://www.swissre.com/risk-knowledge/mitigating-climate-risk/economics-of-climate-change-impacts-for-asia.html"
        }
    },
    {
        "id": "20",
        "title": "Which Asia-Pacific country published the first stewardship code for responsible investment in the region? ",
        "image": "url",
        "answers": [
            "Malaysia",
            "Singapore",
            "Hong Kong"
        ],
        "correct": "Japan",
        "description": "Japan ratified its stewardship code in 2013 and published the final version in 2014. Since then, Singapore, Australia, Malaysia, Thailand, Hong Kong, Taiwan, India and South Korea have introduced stewardship codes. ",
        "source": {
            "CFA Institute": "https://www.cfainstitute.org/-/media/documents/article/position-paper/stewardship.pdf",
            "FSA": "https://www.fsa.go.jp/en/refer/councils/stewardship/20140407.html "
        }
    },
    {
        "id": "21",
        "title": "Which is not a component of effective investment stewardship?",
        "image": "url",
        "answers": [
            "Constructive engagement with investee companies ",
            "Responsible oversight of capital",
            "Advocating for high standards of corporate governance "
        ],
        "correct": "Short-term profit maximisation",
        "description": "Investment stewardship refers to engagement with public companies to promote corporate governance practices that are consistent with encouraging long-term value creation for shareholders in the company. ",
        "source": {
            "CFA Institute": "https://www.cfainstitute.org/-/media/documents/article/position-paper/stewardship.pdf",
            "FSA": "https://www.fsa.go.jp/en/refer/councils/stewardship/20140407.html "
        }
    },
    {
        "id": "22",
        "title": "Which is not considered a condition for investing for long-term value creation? ",
        "image": "url",
        "answers": [
            "Long-term investment horizon",
            "Keeping the investment chain short",
            "Long-term alignment of the mandates between asset owners and asset managers "
        ],
        "correct": "Passive management in diversified portfolios",
        "description": "In their research, Dirk Schoenmaker and Willem Schramade identified conditions for investing for long-term value creation: <br /> <ol> \t<li>Long investment horizons</li> \t<li>Active management in concentrated portfolios </li> \t<li>Effective engagement with companies </li> \t<li>Long-term alignment of the mandates of asset owners and asset managers </li> \t<li>Keeping the investment chain short </li> \t<li>Performance analysis of value-added in the real economy </li> </ol>",
        "source": {
            "EFMA": "https://efmaefm.org/0efmameetings/efma annual meetings/2018-Milan/papers/EFMA2018_0206_fullpaper.pdf"
        }
    },
    {
        "id": "3",
        "title": "Which is the fastest sinking city in the world?",
        "image": "url",
        "answers": [
            "Male, Maldives",
            "Chittagong, Bangladesh",
            "Venice, Italy"
        ],
        "source": {
            "BBC": "https://www.bbc.com/news/world-asia-44636934​"
        },
        "correct": "Jakarta, Indonesia",
        "description": "Indonesia moved its capital from Jakarta to Kalimantan and renamed it Nusantara. Researchers predict that 95% of North Jakarta will be submerged by 2050. "
    },
    {
        "id": "4",
        "title": "How many of the 25 cities most exposed to a 1-metre sea-level rise are in Asia? ",
        "image": "url",
        "answers": [
            "4",
            "21",
            "13"
        ],
        "correct": "19",
        "description": "7 of these 19 cities are in the Philippines alone.",
        "source": {
            "Eco-Business": "https://www.eco-business.com/news/southeast-asia-facing-calamitous-weather-extremes-as-15c-global-warming-to-hit-by-2030s-ipcc-report/ "
        }
    },
    {
        "id": "5",
        "title": "How many could be pushed into extreme poverty by 2030 because of climate change? ",
        "image": "url",
        "answers": [
            "56 million",
            "89 million",
            "46 million"
        ],
        "correct": "132 million",
        "description": "Extreme poverty is defined as those living below US$1.90 a day. According to the World Bank, the impact of climate change on poverty can be halved, if nations stay on course with the UNSDGs.",
        "source": {
            "World Bank": "https://blogs.worldbank.org/climatechange/covid-climate-change-and-poverty-avoiding-worst-impacts"
        }
    },
    {
        "id": "6",
        "title": "An increase of 1.5°C in global temperature would expose 245 million people to a new or aggravated water shortage. <br /><br />How many people would be affected at +2°C? ",
        "image": "url",
        "answers": [
            "327 million",
            "280 million",
            "523 million"
        ],
        "correct": "490 million",
        "description": "0.5°C may seem insignificant to the individual, but its effects on a global scale are detrimental to millions. ",
        "source": {
            "IMF": "https://www.imf.org/en/Publications/fandd/issues/2021/09/climate-change-and-inequality-guivarch-mejean-taconet"
        }
    },
    {
        "id": "7",
        "title": "Which of the following can be recycled in Singapore?",
        "image": "url",
        "answers": [
            "Dirty diaper",
            "Mirror",
            "Potato chip bag"
        ],
        "correct": "Empty aerosol can",
        "description": "",
        "source": {
            "NEA": "https://www.nea.gov.sg/docs/default-source/our-services/waste-management/list-of-items-that-are-recyclable-and-not.pdf"
        }
    },
    {
        "id": "8",
        "title": "What proportion of global waste is generated by high-income countries?",
        "image": "https://datatopics.worldbank.org/what-a-waste/img/map2.png",
        "answers": [
            "11%",
            "23%",
            "80%"
        ],
        "correct": "34%",
        "description": "High-income countries account for 16% of the world’s population, but generate 34% of its waste.  <br /><br /> In contrast, low-income countries account for 9% of the world’s population, but generate about 5% of its waste.",
        "source": {
            "World Bank (Open Knowledge)": "https://openknowledge.worldbank.org/handle/10986/30317 ",
            "World Bank (Data Topics)": "https://datatopics.worldbank.org/what-a-waste/img/map2.png "
        }
    },
    {
        "id": "9",
        "title": "How much energy could recycling one aluminium can save? ",
        "image": "url",
        "answers": [
            "Enough to operate a light bulb for 10 hours",
            "Enough to run a 1200 watt microwave for 1 minute",
            "Enough to fully charge a smartphone"
        ],
        "correct": "Enough to run a computer for 3 hours",
        "description": "Recycling aluminium takes 95% less energy than producing it from scratch.",
        "source": {
            "Connecticut State": "https://portal.ct.gov/DEEP/Reduce-Reuse-Recycle/Municipal-Recycling-Resource-Center/As-A-Matter-of-Fact",
            "Stanford": "https://lbre.stanford.edu/pssistanford-recycling/frequently-asked-questions/frequently-asked-questions-benefits-recycling"
        }
    }
];