import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { auth, logInWithEmailAndPassword} from "../../hooks/db";
import { useAuthState } from "react-firebase-hooks/auth";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user){history.push("/Responses")};
  }, [user, loading]);

  return (
    <div className={"center"}>
      <div>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button className={"button--submit"}
          onClick={() => logInWithEmailAndPassword(email, password)}
        >
          Login
        </button>
        <div>
         
        </div>
      </div>
    </div>
  );
}

export default Login;