import {React} from "react";
import {useHistory} from "react-router-dom";
import thankYouImage from "../images/thank-you.svg";

const ThankYou = () => {
  const history = useHistory();

  const startAgainClick = () => {
    document.body.style.setProperty("--page-background-color", '#000');
    history.push("/");
  };

  return (
    <div className={'thank-you'}>
      <div className={'center'}>
        <img className={'thank-you-image'} src={thankYouImage} alt={'Thank you!'}/>
      </div>
      <div className={'center'}>
        <p>If you have any comments or feedback, please email <a
          href={'mailto:hello@stewardshipcommons.com'}
          title={'Feedback email address'}>hello@stewardshipcommons.com</a>.
        </p>
      </div>
      <button onClick={startAgainClick} className={"button button--start"} style={{marginBottom: '16px'}}>Start Again</button>
    </div>
  );
}

export default ThankYou;