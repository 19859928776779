import { format } from "date-fns";

export const COLUMNS = [
  {
    Header: "Index",
    Footer: "Index",
    accessor: "index",
    disableFilters: true,
    sticky: "left"
  },
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
    disableFilters: true,
    sticky: "left"
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    sticky: "left"
  },
  {
    Header: "Submitted",
    Footer: "Submitted",
    accessor: "created",
    sticky: "left",
     Cell: ({ value }) => {
     return format(new Date(value), "dd/MM/yyyy HH:MM:ss");
    }
  }
];

export const GROUPED_COLUMNS = [
  {
  }
];
