import {decode} from "html-entities";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import store from "../redux/store";
import progressStep1 from "../images/progress_1.svg";
import progressStep2 from "../images/progress_2.svg";
import progressStep3 from "../images/progress_3.svg";
import {ADD_QUESTION_ITEM} from "../redux/actionsTypes";
import {response} from "../hooks/questionJson";

const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

function validURL(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str);
}

function randomizeArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

const NUMBER_OF_QUESTIONS = 3;

const Questions = () => {
  const history = useHistory();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionTitle, setQuestionTitle] = useState("");
  const [options, setOptions] = useState([]);
  //const { response, loading } = useQuestions();
  const loading = false;
  const [lastAnswer, setLastAnswer] = useState("");
  const [lastCorrectAnswer, setLastCorrectAnswer] = useState("");
  const [lastQuestion, setLastQuestion] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [answerIsCorrect, setAnswerIsCorrect] = useState(false);
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    if (questionList?.length) {
      const questionEntity = questionList[questionIndex];
      setQuestionTitle(questionEntity.title);
      let answers = [...questionEntity.answers];
      answers.splice(
        getRandomInt(questionEntity.answers.length),
        0,
        questionEntity.correct
      );
      setLastQuestion(questionEntity);
      setOptions(answers);
    }
  }, [questionList, questionIndex]);

  useEffect(() => {
    if (response?.length) {
      const questionList = randomizeArray(response).splice(
        0,
        NUMBER_OF_QUESTIONS
      );
      setQuestionList(questionList);
    }
  }, [response]);

  if (loading) {
    return <h1>loading....</h1>;
  }

  const handleClickAnswer = (e) => {
    const question = questionList[questionIndex];
    store.dispatch({
      type: ADD_QUESTION_ITEM,
      payload: {
        qid: question.id,
        title: question.title,
        answer: e.target.textContent,
        correct: e.target.textContent === question.correct,
      },
    });

    setLastAnswer(e.target.textContent);
    setLastCorrectAnswer(question.correct);
    if (e.target.textContent === question.correct) {
      setAnswerIsCorrect(true);
    }
    setShowResult(true);
  };

  const handleNextQuestion = () => {
    setShowResult(false);
    setAnswerIsCorrect(false);
    if (questionIndex + 1 < questionList.length) {
      setQuestionIndex(questionIndex + 1);
    } else {
      history.push("/form");
    }
  };

  const handleSkipQuestion = (e) => {
    const question = questionList[questionIndex];
    store.dispatch({
      type: ADD_QUESTION_ITEM,
      payload: {
        qid: question.id,
        title: question.title,
        answer: "skip",
        correct: false,
      },
    });
    if (questionIndex + 1 < questionList.length) {
      setQuestionIndex(questionIndex + 1);
    } else {
      history.push("/form");
    }
  };

  function progressStep(step) {
    switch (step) {
      case 1:
        return <img src={progressStep1} alt="Progress Bar - Step 1"/>;
      case 2:
        return <img src={progressStep2} alt="Progress Bar - Step 2"/>;
      case 3:
        return <img src={progressStep3} alt="Progress Bar - Step 3"/>;
      default:
        return <></>;
    }
  }

  function renderSource(source) {
    const result = Object.entries(source).map(([key, value], index) => {
      return Object.keys(source).length < 3 ? (
        <>
          <a key={key} href={`${value}`} target="_blank" rel="noreferrer">
            {key}
          </a>{" "}
          {Object.keys(source).length === index + 1 ? (
            ""
          ) : (
            <span style={{width: "40px"}}>
              {"  "}and{"  "}
            </span>
          )}
        </>
      ) : (
        <>
          <a key={key} href={`${value}`} target="_blank" rel="noreferrer">
            {key}
          </a>
        </>
      )
    })
    return result;
  }

  return (
    <div className={"questions"}>
      <div className={"progress"}>{progressStep(questionIndex + 1)}</div>
      <h1
        dangerouslySetInnerHTML={{
          __html: questionTitle,
        }}
      ></h1>

      {showResult ? (
        <>
          <button
            className={answerIsCorrect ? "button--correct" : "button-wrong"}
          >
            {lastAnswer}
          </button>
          <div className={"center"}>
            <p className="the-answer">
              The answer is: <br/>{" "}
              <span className={"text--bold"}>{lastCorrectAnswer}</span>
            </p>
          </div>
          {lastQuestion.description && (
            <div className={"center"}>
              <p
                className="description-answer"
                dangerouslySetInnerHTML={{
                  __html: lastQuestion.description,
                }}
              ></p>
            </div>
          )}
          {lastQuestion.image && validURL(lastQuestion.image) && (
            <div className={"center"}>
              <img
                style={{maxWidth: "100%", marginBottom: "10px"}}
                src={lastQuestion.image}
                alt={"Answer"}
              ></img>
            </div>
          )}
          {lastQuestion.source && (
            Object.keys(lastQuestion.source).length < 3 ? (
              <div className={'center'}>
                <span style={{width: "65px"}}>Source: </span>{" "}
                {renderSource(lastQuestion.source)}
              </div>
            ) : (
              <div className={'source-display'}>
                <span style={{width: "65px"}}>Source: </span>{" "}
                <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                  {renderSource(lastQuestion.source)}
                </div>
              </div>
            )

          )}
          <button
            onClick={handleNextQuestion}
            className={"button--submit"}
            style={{width: "200px", marginBottom: "15px"}}
          >
            {questionIndex + 1 === questionList.length
              ? "Now What?"
              : "Next Question"}
          </button>
        </>
      ) : (
        <>
          {options.map((data, id) => (
            <button key={id} onClick={handleClickAnswer}>
              {decode(data)}
            </button>
          ))}
          <button
            onClick={handleSkipQuestion}
            className={"button--skip-question"}
          >
            Skip Question
          </button>
        </>
      )}
    </div>
  );
};

export default Questions;
