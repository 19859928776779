import {
  ADD_QUESTION_ITEM,
  CLEAR,
} from "./actionsTypes";

const initialState = {
  questionItems:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case ADD_QUESTION_ITEM:{
      return {
        ...state,
        questionItems:[
          ...state.questionItems,
           action.payload
          ]
      }
    }
    case CLEAR:{
       return {
        ...state,
        questionItems:[
          ]
      }
    }
    default:
      return state;
  }
};
 

export default reducer;