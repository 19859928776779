import { BasicTable } from "./BasicTable";
import React, { useState,useEffect } from "react";
import { COLUMNS } from "./column";
import { db,auth,logout } from "../../hooks/db"
import {
  collection,startAt,startAfter,limit,
  query,onSnapshot, orderBy,
} from "firebase/firestore";
import { useHistory } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
const Responses = () => {
   const history = useHistory();
   const [response,setResponse]= useState([]);
   const [lastDoc, setLastDoc] = useState(null);
   const [size, setSize] = useState(50);
   const [canPreviousPage,setCanPreviousPage]=useState(true);
   const [user, loading, error] = useAuthState(auth);
   const [lastIndex, setLastIndex] = useState(1);

  const nextPage = (e) => {
    e.preventDefault();
     load();
  };

  useEffect((e) => {
    if (user) {
     load();
    }else{
     history.push("./login");
    }
  }, [user]);


  const load = (e) => {
    if(!user){
      history.push("./login");
    }

    const q = (lastDoc==null) ? query(collection(db, 'quiz-response'),orderBy('created','desc'),limit(size)):
         query(collection(db, 'quiz-response'),orderBy('created','desc'),startAfter(lastDoc),limit(size));

    onSnapshot(q, (querySnapshot) => {
      let idx=lastIndex;

      setResponse(querySnapshot.docs.map(doc => ({
        index:idx++,
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        created: new Date(doc.data().created.toDate().getTime())
      })))

      setLastIndex(idx);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length-1]);
      if(querySnapshot.docs.length<=0){
        setResponse([]);
        setCanPreviousPage(false);
      }
      if(querySnapshot.docs.length < size){
        setCanPreviousPage(false);
      }
    })
  }

   return (
    <div  className={"center"}>
      <table>
        <tbody>
        <tr>
          <td>
        <div>
        Logged in as
        <div>{user?.email}</div>
        <button className={"button--submit"}  onClick={logout}>
          Logout
        </button>
      </div></td>
        </tr>
        <tr>
          <td><div  className={"center"}>
          <BasicTable data={response} columns={COLUMNS} />
        </div></td>
        </tr>
        <tr>
          <td><button onClick={nextPage} disabled={!canPreviousPage}>
         {'More'}
        </button></td>
        </tr>
        </tbody>
        </table>
    </div>
  
  );
}

export default Responses;