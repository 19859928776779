import {Component, React} from "react";
import progressStep4 from '../images/progress_4.svg';
import {withRouter} from 'react-router-dom';
import {addDoc, collection, Timestamp, updateDoc} from "firebase/firestore";
import store from '../redux/store';
import {CLEAR} from "../redux/actionsTypes";
import {db} from "../hooks/db";
import ClipLoader from "react-spinners/ClipLoader";

const regExpEmail = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

const formValid = ({isError, ...rest}) => {
  let isValid = true;
  Object.values(isError).forEach(val => {
    if (val.length > 0) {
      isValid = false
    }
  });
  return isValid;
};

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#FFFFFF",
  position: "absolute",
  top: 26,
  left: "15%",
  borderWidth: 4
};

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      username: '',
      email: '',
      isError: {
        username: '',
        email: '',
      },
      docRef: {}
    }
  }

  async componentDidMount() {
    const docReference = await addDoc(collection(db, 'quiz-response'), {
      quizId: 1,
      name: this.state.username,
      email: this.state.email,
      questionItems: store.getState().questionItems,
      created: Timestamp.now()
    })
    this.setState({docRef: docReference})
  }

  handleButtonClicked = async (e) => {
    let mail = '';
    let un = '';
    let err = false;
    if (this.state.username.length < 3) {
      un = "Please provide a name."
      err = true;
    }
    if (this.state.email.length <= 0) {
      mail = "Please provide a email address.";
      err = true;
    }
    if (!regExpEmail.test(this.state.email)) {
      mail = "Please provide a valid email address.";
      err = true;
    }

    this.setState({
      isError: {username: un, email: mail}
    });

    if (!err && formValid(this.state)) {
      try {
        this.setState({
          loading: true
        });
        updateDoc(this.state.docRef, {
          quizId: 1,
          name: this.state.username,
          email: this.state.email,
          questionItems: store.getState().questionItems,
          created: Timestamp.now()
        });
        store.dispatch({type: CLEAR});
        this.props.history.push("/thank-you");
      } catch (err) {
        console.error(err)
      } finally {
        this.setState({loading: false})
      }
    }
  };


  formValChange = (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let isError = {...this.state.isError};
    switch (name) {
      case "username":
        isError.username =
          value.length < 3 ? "At least 3 characters required." : "";
        break;
      case "email":
        isError.email = !regExpEmail.test(value)
          ? "Please provide a valid email address."
          : "";
        break;
      default:
        break;
    }
    this.setState({
      isError,
      [name]: value
    })
  };

  render() {
    const {isError, loading} = this.state;
    return (

      <div className={'questions'}>
        <h1 style={{
          fontSize: "26px", color: "#006887", lineHeight: "27px", textAlign: "center", marginTop: "25px",
          fontFamily: "system-ui", fontStyle: "italic", marginBottom: "35px"
        }}>Stewarding the commons by the commons</h1>
        <hr style={{marginBottom: "35px"}}></hr>
        <div className={'progress'}>
          <img src={progressStep4} alt="Progress Bar - Completed"/>
        </div>
        <div className={'form'}>
          <p>The natural resources we share, our global commons, are under threat. </p>
          <p>Climate change, income inequality and social injustice cannot be overcome without the common people — us.
            We are stewards of our global commons.</p>
          <p>Stewardship Commons will be a crowdsourced content platform for ESG, responsible investing, steward
            leadership and more.</p>
          <p>If you would like to be a contributor, get in touch.</p>


          <input placeholder={'Name'} onChange={this.formValChange.bind(this)} value={this.state.username}
                 name="username"/>
          {isError.username.length > 0 && (
            <span className="form-feedback"><p>{isError.username}</p></span>
          )}
          <input placeholder={'Email address'} onChange={this.formValChange.bind(this)} value={this.state.email}
                 name="email"/>
          {isError.email.length > 0 && (
            <span className="form-feedback"><p>{isError.email}</p></span>
          )}

          <div style={{textAlign: "center", position: "relative", width: "200px", margin: "auto", display: "block"}}>
            <button onClick={this.handleButtonClicked.bind(this)} className={"button--submit"} disabled={loading}>
              Submit
            </button>
            <ClipLoader color={"#ffffff"} loading={loading} css={override} size={25}/>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(Form);