import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Questions from "./pages/Questions";
import Form from "./pages/Form.js";
import stewardshipCommonsLogo from "./stewardship_commons_logo.svg";
import stewardshipAsiaLogo from "./stewardship_asia_logo.svg";
import "./App.scss";
import Home from "./pages/Home.js";
import { connect } from "react-redux";
import ThankYou from "./pages/ThankYou.js";
import Responses from "./pages/admin/Responses.js";
import Login from "./pages/admin/Login.js";


import React from "react";
function App() {
  const handleClickOnLogo = (event) => {
    event.preventDefault();
    window.location.href = "/";
  };

  let header = (
    <div>
      <header style={{ textAlign: "center" }}>
        <img
          src={stewardshipCommonsLogo}
          width={"255px"}
          height={"auto"}
          alt="Stewardship Commons Logo"
          onClick={handleClickOnLogo}
        />
      </header>
    </div>
  );

  let footer = (
    <footer>
      <p>A content and community hub developed by&nbsp;&nbsp;&nbsp;</p>
      <img src={stewardshipAsiaLogo} alt="Stewardship Asia Logo" width={"155px"} />
    </footer>
  );

  function center(content) {
    return (
      <div className={"container-center"}>
        {header}
        {content}
      </div>
    );
  }

  function pageLayout(content) {
    return (
      <div className={"layout"}>
        {center(
          <>
            {content}
            {footer}
          </>
        )}
      </div>
    );
  }
  function pageLayoutBlack(content) {
    return (
      <div className={"layout-black"}>
        {center(
          <>
            {content}
            {footer}
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact>
            <div className={"home"}>{center(<Home />)}</div>
          </Route>
          <Route path="/questions" exact>
            {pageLayout(<Questions />)}
          </Route>
          <Route path="/form">{pageLayoutBlack(<Form />)}</Route>
          <Route path="/thank-you">{pageLayoutBlack(<ThankYou />)}</Route>
          <Route path="/responses">{pageLayoutBlack(<Responses />)}</Route>
          <Route path="/login">{pageLayoutBlack(<Login />)}</Route>
        </Switch>
      </Router>
    </>
  );
}

export default connect()(App);
