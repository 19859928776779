import {useHistory} from "react-router-dom";
import stewardshipAsiaLogo from "../stewardship_asia_logo.svg";

const Home = () => {
  const history = useHistory();

  const proceedToQuestions = () => {
    document.body.style.setProperty("--page-background-color", '#FBF5F3');
    history.push("/questions");
  };

  return (
    <>
      <h1 style={{fontSize:"26px", color:"#006887", lineHeight:"27px", textAlign:"center", marginTop:"25px",
      fontFamily:"system-ui", fontStyle:"italic",marginBottom:"35px" }}>Stewarding the commons by the commons</h1>
      <hr style={{marginBottom:"35px"}}></hr>
      <p>A crowdsourced hub where you can consume and contribute insights on ESG, responsible investing,
        steward leadership and more.</p>
      <p>Coming soon.</p>
      <p>In the meantime, how much do you know about our planet?</p>
      <div className={'center'}>
        <button onClick={proceedToQuestions} className={"button button--start"}>Let's Go</button>
      </div>
      <hr style={{marginTop:"35px"}}></hr>
      <div className={'logo-container'}>
      <p style={{fontSize:"10px", marginBottom:"-5px", fontWeight:"200"}}>A content and community hub developed by&nbsp;&nbsp;&nbsp;</p>
        <img src={stewardshipAsiaLogo} alt="Stewardship Asia Logo" width={"155px"}/>
      </div>
    </>
  )
    ;
};

export default Home;
